import { Injectable } from '@angular/core';
import Order from '../../classes/Order';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
orderKitchenSelect: Order;
  constructor() { }
setOrderSelected(order: Order){
  this.orderKitchenSelect = order;
}
}
