import Dish from './Dish';

export default class Menu{
    _id: number
    kitchen_id: number //id
    date: string //timestamp of start of the day 
    shift_id: number //id
    menu_dishes: Dish[] //array of ids
    constructor(menu?: any) {
        for(let key in menu){
            this[key] = menu[key];
        }
    }
}