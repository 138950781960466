import { RadioBtn } from '../radio-btn/radio-btn.component';

export interface PopupProps {
    logo?: boolean;
    popupClass?: string;
    title: string;
    titleClass?: string;
    fields: Field[];
    buttons: PopupButton[];
}

export interface PopupButton{
    text: string;
    color?: BtnColor;
    onClickText: string;
}

export interface Field {
    type: FieldType;
    title: string;
    key?: string;
    center?: boolean;

    //text
    value?: string;

    //select
    options?: any[];
    typeOption?: string,
    defaultValue?: string;
    selectedItem?: any;
    isScrollDown?: Boolean;

    //input
    hasError?: boolean;
    errorText?: string;
    placeholder?: string; 

    //radioBtn
    radioBtnOptions?: RadioBtn[];
}

export enum FieldType {
    input = "input",
    select = "select",
    text = "text",
    warning = "warning",
    radioBtn = "radioBtn",
    closeIcon= "closeIcon",
    textError= "textError"
}

export enum BtnColor {
    blue = "blue",
    white = "white",
}